/* You can add global styles to this file, and also import other style files */
.fill,
.center {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.center {
  margin: auto;
}